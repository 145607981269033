import React, { Fragment } from "react"

import Sub from "~layout/sub"
import aside from "~content/products/aside"

import { HeadingIcon } from "~styled"

const crumbs = [
  { text: "Products", to: "/products" },
  { text: "Dudu Chinese", to: "/products/dudu/" },
]

const DuduPage = () => {
  let content = (
    <Fragment>
      <HeadingIcon>
        <img src={require("~images/products/dudu.png").default} alt="Dudu" title="Dudu" />
      </HeadingIcon>
      <h1>Learn Chinese. Love Chinese.</h1>
      <h4>To master a language, read more. Dudu helps you read and improve Chinese painlessly.</h4>
      <p>
      After years of collaborating with educators, parents, and learners, we have identified that the most significant challenge in learning Chinese as a second language is the monotonous and uninspiring textbooks and lessons. This often results in children developing a negative attitude towards the language. However, with Dudu, you can rest assured that your child will not have to endure another tedious Mandarin learning experience. We offer a solution that is both engaging and effective, ensuring that your child enjoys the process of learning Chinese.
      </p>
      <p>
      Dudu is designed to cultivate your child's love for Chinese with over 1,000 colorful and interactive Chinese e-books covering a wide range of topics, from history to science, from Chinese idioms to stories. Each e-book features quick and concise dictionary explanations for every word and professional narration for every sentence. Dudu's unique features include an adaptive online placement test that assesses your child's reading competency, and an automated reading program that recommends level-appropriate books as the child progresses through the levels.
      </p>
      <p>
      Dudu also offers many games and online speaking activities to improve your child's reading, listening, and speaking abilities in a fun environment. Our reading system has been proven to be effective in helping children of all ages learn Chinese as a second language better and faster. It is widely used in schools and homes worldwide and is highly regarded by educators and parents alike.
      </p>
      <p>
      Dudu is aligned with HSK/YCT levels and popular Chinese L2 textbooks such as Chinese Made Easy (轻松学汉语) and Huanle Huoban (欢乐伙伴). Contact our Dudu education specialists to learn how you can enhance your child's learning with Dudu and these programs and textbooks.
      </p>
      {/* <p>
        <img src={require('~images/products/dudu.png').default} className="img-responsive margin-auto block" alt="Dudu" />
      </p> */}

      <div className="product-features">
        <div className="product-feature-item">
          <img src={require('~images/products/Dudu/dudu-feature1.png').default} className="img-responsive margin-auto block" alt="e-Books" title="Dudu's extensive collection of over 1000 e-books" />
          <p>Dudu's extensive collection of over 1,000 e-books is tailored to match learners' abilities and interests, making it easier for learners to find suitable books.</p>
        </div>
        <div className="product-feature-item">
          <img src={require('~images/products/Dudu/dudu-feature7.png').default} className="img-responsive margin-auto block" alt="Dudu reading framework" title="Dudu reading framework" />
          <p>Dudu reading framework is designed with level 0 - 12 by our experienced education researchers. We employed AI algorithms to align with HSK/YCT and other widely used Chinese L2 textbooks.</p>
        </div>
        <div className="product-feature-item">
          <img src={require('~images/products/Dudu/dudu-feature3.png').default} className="img-responsive margin-auto block" alt="Dudu's reading interface" title="Dudu's reading interface" />
          <p>Dudu's reading interface is equipped with a comprehensive dictionary and professional narration, allowing learners to immerse themselves in the books without any interruption.</p>
        </div>
        <div className="product-feature-item">
          <img src={require('~images/products/Dudu/dudu-feature5.png').default} className="img-responsive margin-auto block" alt="Dudu's Interactive Games" title="Dudu's Interactive Games" />
          <p>Dudu offers various interactive games that reinforce reading, listening, and speaking skills, making learning an enjoyable experience for learners.</p>
        </div>
      </div>

      <div className="client-testimonial">
        <div className="client-testimonial-body">
        <p>It can attract the attention of students through exquisite illustrations and moving animations.  Wah, I love using Dudu!</p>

<p>I really like the dialogic questions provided by Dudu.  After finishing the books, I don’t need to hassle to check my students’ reading comprehension. So, to all teachers, this is a great teaching resource.</p>
        </div>
        <div className="client-testimonial-footer">

         <div className="client-avatar"><img src={require("~images/products/Dudu/client-testimonial.jpg").default} alt="Teacher Jessy Tu" /></div>
         <div className="client-info"> - Teacher Jessy Tu,<br/>
          Mandarin Teaching Influencer</div>
        </div>
      </div>
<br/>
      <p className="text-center">
      <a href="https://dudu.town" title="Visit Dudu Chinese">
        <img src={require("~images/products/Dudu/dudu.png").default} alt="Dudu" title="Dudu" className="margin-auto img-responsive block" />
        Visit Dudu Chinese</a>
      </p>
      <h5 className="contactus-info">Contact Us</h5>
      <p>For more information or sales enquiries, email us at <a href="mailto:biz@commontown.com" alt="" >biz@commontown.com</a> or call us at <a href="tel:6848-8900" alt="">6848-8900</a>.</p>

      {/* <p>For more information, visit <button type="button" className="type-link" onClick={() => window.open('https://dudu.town')} onKeyDown={() => window.open('https://dudu.town')}  >dudu.town</button>.</p>

      <p>For sales enquiries, email us at <a href="mailto:biz@commontown.com" alt="">biz@commontown.com</a> or call us at <a href="tel:6848-8900" alt="" >6848-8900</a>.</p> */}
    </Fragment>
  )

  return <Sub title="Dudu Chinese" crumbs={crumbs} content={content} aside={aside} />
}

export default DuduPage
